import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import webtofaximg from '../Images/webtofax.png'
import faxtomail from '../Images/faxtomail.png'
import '././css/SecureFaxExplanation.css'

const SecureFaxExplanation = () => {
    const senddata = [
        { label: "Compose email using your MDofficeMail or any other HIPAA compliant email account" },
        { label: <>In the “To” field enter the 11-digit fax number of the recipient followed by @sendFaxtone.com (Example: If the recipient fax No. is 1-630-299-7638, type <a href="mailto:16302997638@SendFaxtone.com">16302997638@SendFaxtone.com</a>)</> },
        { label: "Attach the documents you want to fax and click send." },
        { label: "Faxes are delivered to the recipient’s fax machine. Message typed in the subject line and body of the email are pulled into the cover sheet of the fax.  You will receive a confirmation email once the fax is transmitted." },
    ];


    const receivedata = [
        {
            label: "You will receive an email with attachment from fax@Faxtone.com",
        },
        {
            label: " Click on the attachment. Received fax is displayed."
        },
        { label: "You can print or store the fax in your computer." }
    ]


    const sendwebfax = [
        <>Login to <a href="https://faxtone.com/login.aspx " target="_blank" >https://Faxtone.com </a> with your user name and password</>,
        "Click on the tab “Compose Fax”",
        "Select ther recipient’s fax number from contacts or enter the  fax number, enter recipient’s name, and enter notes.",
        "Attach required documents by clicking on the browse button, hit “Send Fax”"
    ]

    const receivewebfax = [
        "Login to Faxtone portal with your user name and password.",
        "Inbox is displayed by default, which will have all the received faxes.",
        "Select the fax to view, save to local computer, or print."
    ]



    const impdata = [
        "Email to fax: Messages sent only from the registered email address will be accepted and fax will be sent from the server. You can register any number of email addresses. Log into Faxtone portal with your user name and password, go to settings to register email addresses to your account",
        "Received faxes will be forwarded to the email address registered to receive faxes.",
        "Messages are secure and HIPAA compliant only if they are sent/received through a secure and HIPAA compliant email account."
    ]
    return (
        <div className='securefaxexplanationcls'>

            {/*--------------------------row1-----------------*/}
            <Row className='row1 justify-contewnt-center align-items-center '>
                <Col lg={6} className='col1 '>
                    {/* <img className='ms-2' src="http://faxtonedemo.novatales.com/static/media/XJWWMBRueMfaoherWfn2B-transformed.2fa1e2c973dd7669db0d.jpeg" alt='img' /> */}
                    <img  src="https://www.shutterstock.com/shutterstock/photos/2291818679/display_1500/stock-photo-cloud-technology-management-concept-cloud-with-digital-lock-icon-appears-while-businessman-working-2291818679.jpg" alt='img' />
                </Col>
                <Col className='col2 lh-lg' lg={6}>
                    <h1>How Secure Fax Works</h1>
                    <h3 className='my-4'>Technology</h3>
                    <p>Faxtone platform is based on the rock-solid <span className='fw-bold'>TDM technology</span> (Time-division Multiplexing Technology) that allows you to send hundred page documents with multiple attachments and file formats. Faxtone is a virtual inbound/outbound faxing solution with a 99.93% transmission success rate.</p>
                    <p className='my-2'>Most other digital fax solutions are based on ‘Fax over VoIP’ or ‘T-38 fax’  protocol which are unstable and unreliable.</p>
                    <p >Each Faxtone client will be allotted with a local or toll-free number as per their selection. The service is ready to go as soon the subscription is complete. However user has to configure setting for a smooth and effortless use of Faxtone.</p>

                </Col>

            </Row>


            {/*--------------------------row2-----------------*/}
            <Row className='row2'>
                {/* <h1>Secure faxes can be sent and received in 2 different ways</h1> */}
                {/* <h2>Email to Fax – Fax to Email</h2>
                <div className='d-flex'>
                    <img src="https://elements-resized.envatousercontent.com/envato-shoebox/twenty20/production/uploads/items/72d7a915-8a78-49fd-a06e-cdface8c5f7b/source?w=800&cf_fit=scale-down&mark-alpha=18&mark=https%3A%2F%2Felements-assets.envato.com%2Fstatic%2Fwatermark4.png&q=85&format=auto&s=f0867e8655f9dc2e1675e7416689e679983d3c47294f89f8828901a3c7fd3332" width="100%" />
                    <img src="https://elements-resized.envatousercontent.com/envato-shoebox/0da5/27eb-e661-41bf-a087-ee5ab24579b9/IMG_4088.JPG?w=800&cf_fit=scale-down&mark-alpha=18&mark=https%3A%2F%2Felements-assets.envato.com%2Fstatic%2Fwatermark4.png&q=85&format=auto&s=feba77be48a69ca86905ebb94f1c8727cdcb8a1bd198ad4ab21d20347be64982" width="100%" />

                </div> */}

            </Row>

            {/*--------------------------row3 # stepper-container1-----------------*/}
            <div className='stepper-container py-5'>
                <p className='text-center fw-bold my-5'>Secure faxes can be sent and received in 2 different ways</p>

                <h1 className='text-center pt-4'>Email to Fax – Fax to Email</h1>
                <Row className='justify-content-center align-items-center'>
                   <Col lg={3} className='mt-5'>
                   <img src={faxtomail} />
                   </Col>
                </Row>
                <Row className='g-5 row3 justify-content-center  py-5'>
                    <Col lg={6}>
                        <h2 className=' my-lg-5 ms-4'>Sending a Fax:</h2>
                        <div className='stepper-section'>
                            {senddata.map((step, index) => (
                                <div key={index}>
                                    <div className="stepper-header">
                                        <span className="icon">Step-{index + 1}</span>
                                        <span className="label lh-lg">{step.label}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Col>


                    <Col lg={6} >

                        <h2 className='my-lg-5 ms-4'>Receiving  a Fax:</h2>

                        <div className='stepper-section'>
                            {receivedata.map((step, index) => (
                                <div key={index}>
                                    <div className="stepper-header">
                                        <span className="icon">Step-{index + 1}</span>
                                        <span className="label lh-base">{step.label}</span>
                                    </div>
                                </div>
                            ))}
                        </div></Col>
                </Row>
            </div>


            {/* --------------stepper-container --------------*/}
            <div className='stepper-container stepper-bg py-5'>
                <h1 className='text-center pt-md-5'>Website to Fax – Fax to Website</h1>
                <Row className='justify-content-center align-items-center'>
                   <Col lg={3} className='mt-5'>
                   <img src={webtofaximg} />
                   </Col>
                </Row>
                <Row className='g-5 row4 justify-content-center py-5'>

                    <Col lg={6}>
                        <h2 className='my-lg-5 ms-4'>Sending a Fax:</h2>
                        <div className='stepper-section'>
                            {sendwebfax.map((step, index) => (
                                <div key={index}>
                                    <div className="stepper-header">
                                        <span className="icon stepicon">Step-{index + 1}</span>
                                        <span className="label lh-lg">{step}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Col>


                    <Col lg={6}>

                        <h2 className=' my-lg-5 ms-4'>Receiving  a Fax:</h2>

                        <div className='stepper-section'>
                            {receivewebfax.map((step, index) => (
                                <div key={index}>
                                    <div className="stepper-header">
                                        <span className="icon stepicon">Step-{index + 1}</span>
                                        <span className="label lh-base">{step}</span>
                                    </div>
                                </div>
                            ))}
                        </div></Col>
                </Row>



            </div>

            {/* -------------------row5 --------------------*/}
            <Row className='row5'>
                <Col lg={6} className='my-5'>
                    <h1 className='py-5'>Important</h1>
                    <ul >
                        {
                            impdata.map((resp, id) => {

                                return (
                                    <li id={id}>
                                        {resp}
                                    </li>
                                )
                            })
                        }
                    </ul>
                </Col>
            </Row>
        </div>
    )
}

export default SecureFaxExplanation
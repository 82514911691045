import React from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import '@fontsource/poppins';

// Define keyframes for animation
const rotate = keyframes`
  to {
    transform: var(--anim-rotate);
  }
`;

// Global styles
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Mukta:wght@400;500;600;700&display=swap');


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .page {
 
    color: hsl(0, 0%, 100%);
    background-color:#9db97b;
 
    display: grid;
    grid-template-areas: "main";
 
 
    font-family: 'Poppins', sans-serif;
  }

  .page__main {
    grid-area: main;
  }
`;

// Styled components
const Main = styled.main`
  display: grid;
  align-items: center;
`;

const Card = styled.article`
  background-color: transparent;
  display: grid;
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1.5;
  box-shadow: 0 0 2em 0.5em hsla(0, 0%, 0%, 0.25);
  max-width: 22em;
  margin-inline: auto;
`;

const CardTitle = styled.h3`
  color: hsl(0, 0%, 100%);
  position: absolute;
  top: 1.3em;
    right: -5em;
  width:100%;
  font-size: 35px;
        font-family: 'Roboto Slab';;
  text-shadow: 4px 4px 6px hsla(0, 0%, 0%, 0.5);
  z-index: 4000;

  @media screen and (min-width: 36em) {
    top: 1.3em;
    right: -5em;
  }
`;

const CardContent = styled.div`
  position: relative;

  padding: 2em;
  padding-top: 365px;
  overflow: hidden;

  &::before,
  &::after {
    position: absolute;
    content: "";
   
  }

  &::before {
    --anim-rotate: translate(-50%, -50%) rotate(360deg);
    animation: ${rotate} 5s linear infinite;
    background: linear-gradient(hsl(185, 100%, 50%), hsl(0, 100%, 50%));
    top: 50%;
    left: 50%;
    width: 500%;
    height: 500%;
    transform: translate(-50%, -50%) rotate(0);
  }

  &::after {
    background-color: hsl(234, 41%, 15%);
    top: 0;
    left: 0;
    inset: 0.3em;
    border-radius: 5em 0 0 0;
    box-shadow: 0 0 1em hsla(0, 0%, 0%, 0.5);
  }
`;

const CardDesc = styled.p`
  margin-top: auto;

  font-size: 18px !important;
  position:relative;
   z-index:1;
   font-family: 'Poppins';

  @media screen and (min-width: 36em) {
    font-size: 1em;
  }
`;

const CardWord = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index:1;
  line-height: normal;
  opacity: 0.15;
  transform: translate(-50%, -50%) rotate(-45deg);
  font-weight: 600;
  font-size: 5em;
  filter: drop-shadow(0.05em 0.05em hsl(0, 0%, 0%));
  user-select: none;
`;

// Main component
const Cardscontainer = () => {

  const data = [
    {
      heading: "MDofficeMail Secure Email Account",
      para: "for convenient, secure, and HIPAA compliant faxing through email, Email-to-fax and Fax-to-email",
      fax: "Free email"
    },
    {
      heading: "Port your existing fax number for FREE",
      para: "Move your fax service to Faxtone for FREE!! There will be NO interruption in service.",
      fax: "Free Porting"
    }
  ];

  return (
    <>
      <GlobalStyle />
      <div className="page card-section">
        <Container>
          <Row className="justify-content-center pb-5 mb-5">
            <h1 style={{ color: "black" }} className='text-center my-5 py-5'>Secure Fax</h1>
            {
              data.map((dta, id) => {
                return (
                  <>
                    <Col lg={6} md={12} sm={12}>
                      <Main className="main page__main">
                        <Card className="card main__card" key={id}>
                          <CardTitle className="card__title lh-base">{dta.heading}</CardTitle>
                          <CardContent className="card__content">
                            <CardDesc className="card__desc lh-lg" style={{ color: "white" }}>
                              {dta.para}
                            </CardDesc>
                            <CardWord className="card__word" aria-hidden="true" style={{ color: "white" }}>
                              {dta.fax}
                            </CardWord>
                          </CardContent >
                        </Card>
                      </Main>
                    </Col>
                  </>
                )
              })
            }

          </Row>
        </Container>
      </div>
    </>
  );
};

export default Cardscontainer;


import './App.css';
import Header from './layout/Header';

import Footer from './layout/Footer';
import Home from './components/MainHome/Home';
import { Route, Routes } from 'react-router-dom';
import Securitypage from './components/Securitypage';
import Login from './components/Login';
import Contactus from './components/Contactus';
import Features from './components/Features';
import Faq from './components/Faq';
import Pricingpage from './components/Pricingpage';
import SecureFaxExplanation from './components/SecureFaxExplanation';


function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/hipaa_fax_features" element={<Features />} />
        <Route path="/hipaa_fax_security" element={<Securitypage />} />
        <Route path="/hipaa_fax_faq" element={<Faq />} />
        <Route path="/hipaa_fax_contact" element={<Contactus />} />
        <Route path="/login" element={<Login />} />
        <Route path="/hipaa_fax_plans" element={<Pricingpage />} />
        <Route path="/how_hipaa_fax_works" element={<SecureFaxExplanation />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

import React, { useState } from 'react'
import '././css/Securitypage.css'
import { Card, CardBody, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Safeguards from './Safeguards'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-regular-svg-icons';

import Accordion from 'react-bootstrap/Accordion';
const Securitypage = () => {

  const administrativesafeguardsdata = [
    {
      heading: "Security Management Process.",
      para: "MDofficeMail has identified and analyzed potential risks to information and has implemented security measures to reduce risks and vulnerabilities. Faxtone will continue to analyze new threats and take appropriate steps."
    },
    {
      heading: "Security Personnel.",
      para: "MDofficeMail has designated a security manager who will be developing and implementing security policies and procedures."
    },
    {
      heading: "Information Access Management.",
      para: "Access to any information on the MDofficeMail system requires appropriate privileges."
    },
    {
      heading: "Staff Training and Management.",
      para: "Our staff consists of dedicated personnel who are appropriately trained and managed."
    },
    {
      heading: "Quarterly Evaluation.",
      para: "Network security scans are performed. Periodic assessment of our procedures and policies is done."
    }
  ]


  const technicalsafeguardsdata = [
    {
      heading: "Access Control.",
      para: "This includes Unique User Identification, Emergency Access Control, Automatic Logoff, and Encryption and Decryption."
    },
    {
      heading: "Audit Controls ",
      para: "to track user access and file access."
    },
    {
      heading: "Person or Entity Authentication"
    },
    {
      heading: "Transmission Security",
      para: "including Integrity Controls and Encryption."
    },
    {
      heading: "Device and Media Controls",
      para: "including data backup, data storage, and data disposal."
    },
  ]

  const securityfeatures = [
    "HIPAA Business Associate Agreement is signed with accounts.",
    "Emails sent to MDVault can be force expired at anytime.",
    "Automatic session timeout is enforced for message viewing page of MDVault.",

    "Messages can be viewed or downloaded only through an SSL connection.",
    "Minimum password length and complexity is enforced.",

    "Encryption is strictly enforced for all outbound messages.",
    "Recipients can reply securely without having a secure email account.",
    "Emails sent to MDVault will automatically get deleted after a fixed time.",
    "SSL connection is strictly enforced for all services, both at sender’s end as well as the recipient's end, which cannot be modified even by the Domain Admins.",
    "Facility to validate new recipient. New recipient needs to enter a 6-digit code to access email received. This randomly generated, recipient-specific code can be provided only by the sender.",
    "Automatic Webmail session timeout is enforced.",
    "Legal archiving: All the emails are archived remotely for a specified length of time, which can't be edited or deleted.",
    "Emergency Access Procedure: PHI in email communications can be accessed from any location via the Internet. There are also mechanisms for authorized administrative to access account data.",
    "Audit Controls: Audit reports of all logins to WebMail, POP, IMAP, and SMTP services are available to administrators. The reports include the date, time, and the IP address from which logins were made."
  ]

  const [showMore, setShowMore] = useState(false)

  const displayedFeatures = showMore ? securityfeatures : securityfeatures.slice(0, 8);


  const oddItems = securityfeatures.filter((_, index) => index % 2 === 0);
  const evenItems = securityfeatures.filter((_, index) => index % 2 !== 0);

  return (
    <div className='securitypage-cls '>

      {/* ----------------------------------------------row1 ---------------------------------*/}

      <Row className='py-md-5 row1 align-items-center '>
        <Col lg={12} xxl={6} className=' col1'>
          <h1>Security</h1>
          <h2 className='py-4 lh-base'>Protected Health Information (PHI)</h2>
          <p className='pt-3'>Faxtone is a secure, digital fax service over internet. Naturally all security measures and mechanisms are taken care to ensure that your faxes are secure when being transmitted from your desktop to Faxtone server or vice-versa.</p>
          <p className='mt-lg-5'>Further, most important feature of Faxtone is the facility to send and receive faxes through email. Because of this all the required email security is taken care so that the HIPAA complaince requirements are met.</p>
        </Col>
        <Col lg={12} xxl={5} className='col2'>
          <img className='imga' src="https://www.shutterstock.com/shutterstock/photos/1079532959/display_1500/stock-photo-medical-concept-health-protection-modern-technology-in-medicine-1079532959.jpg" alt='img' width="100%" />
        </Col>
      </Row>




      {/* ----------------------------------------------row2 ---------------------------------*/}

      <Row className='row2 justify-content-center align-items-center'>
        <Col lg={12} xxl={5} className='col1'>
          <img className='img1' src="https://www.shutterstock.com/shutterstock/photos/1584441676/display_1500/stock-photo-double-exposure-of-healthcare-and-medicine-concept-doctor-and-modern-virtual-screen-interface-1584441676.jpg" width="100%" />

          <img className='img2' src="https://www.shutterstock.com/shutterstock/photos/2256507437/display_1500/stock-photo-a-medical-worker-works-with-an-electronic-database-and-documents-technology-and-access-information-2256507437.jpg" width="100%" />
          {/* <img src="https://elements-resized.envatousercontent.com/envato-shoebox/9ba1/666a-bd27-4e44-a5e0-b4848150d2df/K-163-Maew-6786-OLJ3911-3-DataProtection.jpg?w=1600&cf_fit=scale-down&mark-alpha=18&mark=https%3A%2F%2Felements-assets.envato.com%2Fstatic%2Fwatermark4.png&q=85&format=auto&s=aff1b41684109dd10a2b788828269e194516fcf1d2a2df7f579447598656cd77" width="100%" /> */}
          {/* <img src="https://elements-resized.envatousercontent.com/envato-shoebox/94c0/2f6b-8dc0-4694-a8e7-675e63d42e52/Medical_Data_DSC5026_f1_p.jpg?w=1600&cf_fit=scale-down&mark-alpha=18&mark=https%3A%2F%2Felements-assets.envato.com%2Fstatic%2Fwatermark4.png&q=85&format=auto&s=809c54f6fc477e95aacc190f9e15c5bc54909ee131076abdadb920ac6107700d"/> */}
        </Col>
        <Col lg={12} xxl={7} className='col2'>
          <h1>HIPAA defines PHI </h1>
          <p className='mt-5'>HIPAA defines PHI as the health information of an individual that identifies the individual or for which there is a reasonable basis to believe it can be used to identify the individual. Individually identifiable health information includes many common identifiers such as name, address, birth date, Social Security Number, etc. Individually identifiable health information relates to the past, present, or future information regarding:</p>

          <ul className='pt-md-3 pt-2 '>
            <li>physical or mental health or condition of the individual,</li>
            <li>provision of healthcare to the individual, or</li>
            <li className='pb-3'>payment for the provision of healthcare to the individual. For more information please visit:</li>
            <Link to="https://www.hhs.gov/hipaa/for-professionals/privacy/laws-regulations/index.html">
              http://www.hhs.gov/ocr/privacy/hipaa/understanding/summary/index.html
            </Link>
          </ul>

        </Col>

      </Row>


      {/* ----------------------------------------------row3 ---------------------------------*/}

      <Row className='row3 p-5 justify-content-center align-items-center'>

        <Col lg={6} className='py-md-5 my-md-5 py-2'>
          <h2 className='text-start para1 pb-4 text-center lh-lg'>HIPAA HITECH legislation and etransmission of PHI - Important factors and main safeguards:</h2>
          <Accordion defaultActiveKey="0" className='mb-md-5'>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Administrative Safeguards:
              </Accordion.Header>
              <Accordion.Body>

                {/*<h2>Security Management Process.</h2>
                <p>MDofficeMail has identified and analyzed potential risks to information and has implemented security measures to reduce risks and vulnerabilities. Faxtone will continue to analyze new threats and take appropriate steps.</p>
                <h2>Security Personnel.</h2>
                <p>MDofficeMail has designated a security manager who will be developing and implementing security policies and procedures.</p>
                <h2>Information Access Management.</h2>
                <p> Access to any information on the MDofficeMail system requires appropriate privileges.</p>
                <h2>Staff Training and Management.</h2>
                <p> Our staff consists of dedicated personnel who are appropriately trained and managed.</p>
                <h2>Quarterly Evaluation.</h2>
                <p>Network security scans are performed. Periodic assessment of our procedures and policies is done.</p>*/}
                <ul>
                  {
                    administrativesafeguardsdata.map((res, id) => {
                      return (

                        <li >
                          <h2>{res.heading}</h2>
                          <p className='lh-lg mt-3'>{res.para}</p>
                        </li>

                      )
                    })
                  }
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Technical Safeguards:
              </Accordion.Header>
              <Accordion.Body>
                {/* <h2>Access Control.</h2>
                <p>This includes Unique User Identification, Emergency Access Control, Automatic Logoff, and Encryption and Decryption.</p>
                <h2>Audit Controls </h2>
                <p> to track user access and file access.</p>
                <h2>Person or Entity Authentication</h2>
                <h2>Transmission Security </h2>
                <p>including Integrity Controls and Encryption.</p>
                <h2>Device and Media Controls</h2>
                <p>including data backup, data storage, and data disposal.</p> */}
                <ul>
                  {
                    technicalsafeguardsdata.map((res, id) => {
                      return (

                        <li >
                          <h2>{res.heading}</h2>
                          <p className='lh-lg mt-3'>{res.para}</p>
                        </li>

                      )
                    })
                  }
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Physical Safeguards:
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li >
                    <h2>Device and Media Controls </h2>
                    <p className='lh-lg mt-3'>including data backup, data storage, and data disposal.</p>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        {/* <Col lg={6}>
          <img src="https://elements-resized.envatousercontent.com/envato-shoebox/9036/8b37-6d70-4397-91a1-71de6272fe19/645d5164036bd923383bc153_withmeta.jpg?w=1600&cf_fit=scale-down&mark-alpha=18&mark=https%3A%2F%2Felements-assets.envato.com%2Fstatic%2Fwatermark4.png&q=85&format=auto&s=0a2239c4f99628913b5ad87a4d4768c7346576406275b1e3ad1f2cfadcf5c8f9" width="100%" />
        </Col> */}

      </Row>

      {/* ----------------------------------------------row4 ---------------------------------*/}

      <Row className='row4 align-items-center'>
        <Col lg={7} className='col1 '>

          <h2 className='lh-base'>Where can I find the official document for the Federal HIPAA HITECH legislation?</h2>
          <p className='py-md-4'>The HITECH legislation is Title XIII of the 2009 American Recovery and Reinvestment act. It can be found on page 112 in the official document at:</p>
          <Link to="https://www.govinfo.gov/content/pkg/BILLS-111hr1enr/pdf/BILLS-111hr1enr.pdf">http://www.gpo.gov/fdsys/pkg/BILLS-111hr1enr/pdf/BILLS-111hr1enr.pdf</Link>
          <h2 className='pt-md-5 lh-base'>Is it secure to send and receive fax through a secure email? Is that HIPAA compliant?</h2>
          <p className='pt-md-4'>The Security Rule of the original HIPAA legislation permits Covered Entities to use email as a way to electronically transmit protected health information (PHI) and requires that steps be taken to protect those transmissions. The requirements are detailed in the Technical Safeguards of the</p>
          <Link to="https://www.hhs.gov/ocr/privacy/hipaa/administrative/securityrule/techsafeguards.pdf">HIPAA Security Rule, section 164.312</Link>

        </Col>
        <Col lg={5}>
          <img src="https://elements-resized.envatousercontent.com/envato-shoebox/0da5/27eb-e661-41bf-a087-ee5ab24579b9/IMG_4088.JPG?w=1600&cf_fit=scale-down&mark-alpha=18&mark=https%3A%2F%2Felements-assets.envato.com%2Fstatic%2Fwatermark4.png&q=85&format=auto&s=3092f40c1cc44da22e239b5d6458ae8ccb76aad6298e7b3eed87d57b640d9eae" width="100%" />
        </Col>
      </Row>

      {/* ----------------------------------------------row5 ---------------------------------*/}
      {/* <Row className='row5 py-md-5 py-3 align-items-center'> */}
      <div className='row5'>
        <Container className='pb-md-5' >
          <Row className='py-md-5 py-3 mb-md-5'>
            <div className='text-center pt-md-5 mt-md-5'>
              <h2 className='lh-lg mt-md-5'>How Faxtone is HIPAA compliant when it is used along with MDofficeMail?</h2>
              <h6 className='pt-3 pb-3 pb-md-5 mb-md-5'>MDofficeMail (and in turn Faxtone) is HIPAA compliant by virtue of the following features:</h6>
            </div>

            {/* 
            {
              displayedFeatures.map((dta, id) => {

                // Determine the class based on the index
                let bgClass = "";

                // Apply green background for 1st, 4th, 5th, 8th, and 9th indices
                if (id === 0 || id === 3 || id === 4 || id === 7 || id === 8 || id === 11 || id === 12) {
                  bgClass = "even-card";
                }
                // Apply pink background for 2nd, 3rd, 6th, and 7th indices
                else if (id === 1 || id === 2 || id === 5 || id === 6 || id === 9 || id === 10 || id === 13) {
                  bgClass = "odd-card";
                }

                // const bgClass = id % 6 === 0 || id % 6 === 3 || id % 6 === 4 ? 'even-card' : 'odd-card';
                return (

                  <Col lg={6}>
                    <Card width="500px" className={`d-flex lh-lg my-2 ${bgClass}`} key={id}>
                      <Card.Body>
                        <Card.Text key={id}>
                          {dta}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                )
              })
            }

            <button
              className="btn btn-primary mt-md-4"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? 'Show Less' : 'Read More'}
            </button> */}




            <div className='compliance-features px-md-5 mx-3 mx-xl-5 '> 


              <div className="grid-container row px-2 py-md-5">
                <ol className="olcards col-lg-6">
                  {oddItems.map((res, id) => (
                    <li className='my-4' key={id * 2} style={{ "--cardColor": ["#739BD0", "#cb9fd2", "#9db97b", "#f15f0e"][(id * 2) % 3] }}>
                      <div className="content p-3">
                        <p className="text lh-base">{res}</p>
                      </div>
                    </li>
                  ))}
                </ol>
                <ol className="olcards col-lg-6" style={{ counterReset: `cardCount 13` }}>
                  {evenItems.map((res, id) => (
                    <li className='my-4' key={(id * 2) + 1} style={{ "--cardColor": ["#739BD0", "#cb9fd2", "#9db97b", "#f15f0e"][(id * 2 + 1) % 3] }}>
                      <div className="content p-3">
                        <p className="text lh-base">{res}</p>
                      </div>
                    </li>
                  ))}
                </ol>

              </div>

            </div>
          </Row>
        </Container>
      </div>
      {/* <Col className='col1'>
          <img src="https://elements-resized.envatousercontent.com/envato-shoebox/c7fd/2cef-2351-442f-a87a-f2ba7f1b229a/2201277.jpg?w=1600&cf_fit=scale-down&mark-alpha=18&mark=https%3A%2F%2Felements-assets.envato.com%2Fstatic%2Fwatermark4.png&q=85&format=auto&s=d2f1b9133d4aa13c2fab43f23f878e2cf73c8b5d808b294aed244ba0ba156905" width="100%" />
        </Col>
        <Col className='col2'>
          <h2 className='lh-lg'>How Faxtone is HIPAA compliant when it is used along with MDofficeMail?</h2>
          <p className='py-3'>MDofficeMail (and in turn Faxtone) is HIPAA compliant by virtue of the following features:</p>
          <ul>
            {
              displayedFeatures.map((dta, id) => {
                return (

                  <li className='d-flex lh-lg py-2' key={id} style={{ listStyle: "none" }}>
                    <FontAwesomeIcon className='fastar mt-2' icon={faStar} />
                    <span className='lh-lg ms-3'>{dta}</span>
                  </li>

                )
              })
            }
            <button
            className="btn btn-primary mt-md-4"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? 'Show Less' : 'Read More'}
          </button>
          </ul>
          
        </Col> */}

      {/* </Row> */}


      {/* ----------------------------------------------row6 ---------------------------------*/}
      <Row className='row6'>
        <Col lg={6} xl={6} className='col1'>
          <img src="https://elements-resized.envatousercontent.com/envato-shoebox/3f9e/331e-ccc5-4532-b729-a56880242ca4/02042024130224.jpg?w=1600&cf_fit=scale-down&mark-alpha=18&mark=https%3A%2F%2Felements-assets.envato.com%2Fstatic%2Fwatermark4.png&q=85&format=auto&s=531e8ebaae7f64bd9cebd7620637c000fc2f76252037dbefeaef50aace4b1596" width="100%" />

        </Col>
        <Col lg={6} xl={6} className='col2'>
          <h2 className='lh-lg'>Is a dedicated server required for HIPAA email compliance?</h2>
          <p className='py-md-4 py-2 '>No, there is no explicit requirement. HIPAA law is 'technology neutral' in that it makes no specific requirements for the implementation of technical security, e.g. the level of encryption (128 bits or 256 bits), the encryption type (RSA, AES, etc.), the level of auditing, etc. The security restrictions MD Officemail enforces ensure that your shared hosting account meets the Technical Safeguards of the HIPAA Security Rule.</p>

        </Col>
      </Row>

    </div>
  )
}

export default Securitypage
import React, { useEffect } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import featuresImageb from '../../Images/XJWWMBRueMfaoherWfn2B-transformed.jpeg'
import './Home.css'
import { useNavigate } from 'react-router-dom'
const FaxtoneFeaturesAndAdvantages = () => {

    const navigate=useNavigate()

    const data = [
        "No telephone line, fax machine, or fax modem required.",
        "Enhanced productivity. No need to print documents to fax them. Send directly from computer.",
        "User-friendly service.",
        "Cost-effective operations – Save time, labor, and money.",
        "Close to 100% reliability, High scalability.",
        "Fax line is never busy!. Number of fax messages from different senders can be received simultaneously."
    ]

    const handleclick=()=>{
     navigate("/hipaa_fax_features")
     window.scrollTo(0, 0);
     
    }
   
    return (

        <div className='fax-features-advantages  pb-5'>
            <Row className='px-3 mx-xl-4 align-items-center'>
                <Col lg={6} className='col2'>
                    {/* <img alt='img' className='mt-5' src={featuresImageb} width="100%"/> */}
                    <img alt='img' className='mt-5 ps-5' src="https://www.shutterstock.com/shutterstock/photos/2189824979/display_1500/stock-photo-business-concept-and-virtual-screen-of-the-online-security-plan-man-use-laptop-for-data-protection-2189824979.jpg" width="800px" height="800px" />
                </Col>
                <Col lg={6} className='col1 pe-5'>
                    <h1 className='my-4 mx-2 lh-base pb-3'>Features and Advantages of Faxtone</h1>
                    <ul>
                        {data.map((dta, ind) => (
                            <li key={ind} className='mt-3 lh-lg'>{dta}</li>
                        ))}
                    </ul>
                    <Button className='ms-3 mt-3 btn' onClick={handleclick}>Read More</Button>
                </Col>

            </Row>
        </div>

    )
}

export default FaxtoneFeaturesAndAdvantages 
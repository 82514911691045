import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './Home.css'

const Archival = () => {
    return (
        <div className='archival-section my-5 py-5'>
            <Container className='my-5'>
                <Row >
                    <Col className='py-3' >
                        <h1 className='text-center'>Legal Archival:</h1>
                        <p className='py-md-5 lh-lg'>When Faxtone is used along with MDofficeMail, all the received and sent
                            secure fax messages are archived in a remote secure server.
                            These messages can't be edited or deleted.</p>
                        <h6 className='fw-bold'>Faxtone plans start from $3.15/mo. No long term contract required.
                            Sending/receiving faxes will be as low as $0.04 per page</h6>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Archival
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter ,faFacebook,faYoutube,faSquareInstagram} from '@fortawesome/free-brands-svg-icons';
import './layout.css'
const Footer = () => {
  return (
    <div className='footer py-5 text-md-start '>
    
      <Container>
        <Row  className='justify-content-xl-center mx-lg-1 mx-md-4 mx-2 row1 pt-4'>
          
          <Col md={5} lg={3} xl={3} className='mb-md-0 mb-4 col1 me-5'>
           <div className='first-col'>
           <h1 className='heading mb-4'><span style={{color:"#80d102"}}>Fax</span><span style={{color:"#0acfec"}}>tone</span></h1>
            <p className='mb-4'> Lorem ipsum dolor sit amet, consec tetur adipiscing elite</p>
            <Row>
                <Col className='icon-section fs-3'>
                <FontAwesomeIcon icon={faFacebook} className='mx-2'/>
                <FontAwesomeIcon icon={faSquareInstagram} className='mx-2'/>
                <FontAwesomeIcon icon={faYoutube} className='mx-2'/>
                <FontAwesomeIcon icon={faTwitter} className='mx-2'/>
                </Col>
            </Row>
            </div>
            </Col>
         
            <Col md={3} lg={3}  xl={2} className='colcls mx-5'>
                <div>
                <h4>Support</h4>
                <p>Terms</p>
                <p>Security</p>
                <p>Privacy Policy</p>
                <p>Documentaion</p>
           
                </div>
            </Col>
      
            <Col md={4} lg={3}  xl={2} className='colcls'>
            <div>
                <h4>Explore</h4>
                <p>Home</p>
                <p>About</p>
                <p>FAQs</p>
                <p>Contact</p>
              
            </div>
            </Col>
        
            <Col md={4} lg={4}  xl={3} className='colcls'>
            <div>
                <h4>Contact Us</h4>
                <p>Phone: 1-630-778-0634</p>
                <p>info@mdofficemail.com</p>
            </div>
            </Col>
           
        </Row>
        <hr/>
        
         <Row className='row2 mt-5 pt-4'>
            <Col >All Rights Reserved @ 2023</Col>
            <Col className='text-md-end'><span className='h2'>By:</span><span className='h1'>MDofficeMail</span></Col>
         </Row>
         
     </Container>
    </div>
  )
}

export default Footer

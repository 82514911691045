import React, { useState } from "react";
import { Container, Card, Button, Collapse, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faChevronDown, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

import '././css/faq.css'
import { Link } from "react-router-dom";


const Faq = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const setupAndTrialFAQs = [
        {
            question: "Is it necessary to sign up for MDofficeMail email account along with Faxtone?",
            answer: 'The answer is Yes and No, based on your requirement. If you are looking only for a HIPAA compliant solution, a) To use Email to Fax and Fax to Email feature, you need to have a secure and HIPAA compliant email account. b) If you want to send and receive faxes only by logging into our web-portal, you need not have a secure email account.',
        },
        {
            question: 'How do I sign up for a Faxtone account?',
            answer: `You can sign up from the <a href="https://mdofficemail.com/subscriptions/orderservices.aspx" target="_blank" rel="noopener noreferrer">Subscription</a> page. Select required number of fax lines, email accounts, add-ons, and follow the links to our secure site to make a payment. Once the credit card is authorized, your account is created, which you can start using immediately. Details of the account, Quick Start Guide, and other information will be emailed to your present email address. In addition, you can take the help of our customer support.\n\nWith your subscription, you will get 25 bonus "Transmission Credits". To purchase more credits, you need to log in to our web portal.`,
        },
        {
            question: "Do you offer free trial?",
            answer: 'Yes, you can try Faxtone for 30 days without any obligation. Please click here to signup for a fully functional free trial of Faxtone and MDofficeMail email. You will get 25 "Transmission Credits" with your trial account. You need to purchase Transmission Credits if you need more during the trial period. You need to provide your credit card information. This is to prevent spam. We take the security of clients very seriously.',
        },
        {
            question: 'How can I convert my Trial Account into a Regular Account?',
            answer: 'Your trial will automatically convert into a regular account at the end of the trial period, and your credit card on file will be charged with the monthly subscription amount. In case you want to cancel your trial account, you need to contact us over the phone before the end of the trial period.',
        },
        {
            question: 'What are "Transmission Credits", how much do they cost, and how to purchase them?',
            answer: `
          <ul>
            <li>"Transmission Credits" are required to send and receive faxes using Faxtone, the secure fax service.</li>
            <li>One credit is required to send or receive one page.</li>
            <li>Each Transmission Credit costs as low as $0.04 (4 cents).</li>
            <li>You can recharge your account by logging into the web-portal and clicking on the "Recharge" button.</li>
            <li>While recharging, you can select the option for auto-refill. The account will be refilled when available credits deplete to 50.</li>
          </ul>
        `,
        },
        {
            question: 'Can I still use my current MDofficeMail email address with Faxtone?',
            answer: 'Yes, you can do that without any problem. However we suggest you to have a different email id for sending and receiving faxes.',
        },
        {
            question: 'Do I need to download or install any software to use Faxtone service?',
            answer: 'Absolutely nothing. No downloads, plug-ins, or installations are necessary.',
        },

        {
            question: "Does the Faxtone service work with my PC/email software/OS?",
            answer: `MDofficeMail works with almost all the systems:
            <ul>
            <li><b> Web Browsers:</b> Internet Explorer 6 and above, Firefox, Apple Safari, Google Chrome, Netscape Communicator, Mozilla, AOL, Opera, Red Hat, Linux, Konqeror, Camino.  </li>
            <li><b> Email Clients:</b> Outlook & Outlook Express, Windows Mail, Thunderbird, Netscape Communicator, Eudora, Apple Mail (MacOS 10.2 or later), Mac-Entourage v.x for MacOS X.</li>
            <li><b>Mobile:</b> MDofficeMail also works with iPhone, iPad, Android, Palm, and Windows Mobile.</li>
            </ul>`,
        }

    ];



    const SecurityFaqData = [

        {
            question: "How does the security work, resulting in HIPAA compliant Fax?",
            answer: 'Security and encryption is enforced without any compromise for all the emails sent from MDofficeMail (for email to fax and Fax to email). An SSL (or TLS) session is initiated before any login or password information is exchanged well before any data is transmitted. Server certificates we use are 100% secure using RSA-powered TLS (Transport Layer Security). \n\n In case of Faxtone users who are not using MDofficeMail email account, Fax to email is very much secure and HIPAA compliant since encrypted messages with fax as attachment are sent without any compromise. However email to fax may not be sucure and HIPAA compliant if MDofficeMail email is not used.',
        },
        {
            question: "Do you support International incoming faxes?",
            answer: 'Local and toll free fax numbers will accept incoming faxes from any origin including an international origin. However, international senders will incur regular charges irrespective of whether yours is a local number or toll free number.',
        },
        {

            question: "What type of phone number will I get?",
            answer: "You can choose a local phone number in the supported area code as well as Toll-Free (800, 888, 877, 866) numbers. These are regular phone numbers just like any other US phone number."
        },
        {

            question: "Can I select my number?",
            answer: "Yes. During signup process you will be asked to check whether fax number with your area code is available or not. After signing up you can log into Faxtone web-portal and select the number from the list of available numbers."
        },
        {

            question: "Can I transfer my exisitng fax/phone number to your service?",
            answer: "We support porting in local geographical areas that we serve. You can check the possibility of porting your number from our website. We do support porting of toll-free fax number too. There is a one-time fee of $20.00 to offset our costs for porting your number, payable only after successful porting of your number. Please note that you must sign up for Faxtone before you can initiate the porting process."
        },
        {

            question: "Where can I find the Federal HIPAA HITECH legislation?",
            answer: `The HITECH legislation is Title XIII of the 2009 American Recovery and Reinvestment act, and can be found here: <br/><a href="https://www.govinfo.gov/content/pkg/BILLS-111hr1enr/pdf/BILLS-111hr1enr.pdf" target="_blank" rel="noopener noreferrer">
            http://www.gpo.gov/fdsys/pkg/BILLS-111hr1enr/pdf/BILLS-111hr1enr.pdf
            </a>`
        },
        {

            question: "What is your policy about sharing customers fax numbers and email addresses?",
            answer: "We respect your privacy. We never rent, sell or share clients' addresses with anyone."
        },
        {

            question: "Do you support email Legal Archiving of all outbound and inbound faxes?",
            answer: "If you are using MDofficeMail along with Faxtone, all faxes send and received through email are both online and offline archived. As required by HIPAA, all incoming and outgoing emails (secure faxes that are sent using email) are stored in a secure remote server which cannot be edited or deleted. You can subscribe for Legal Archiving at the time of signing up."
        },
        {

            question: "Do you have any limit to the number of pages that can be sent in a single fax message?",
            answer: "As such there is no limit to the number of pages when you are sending through our securr fax web-portal. Please make sure that you have suffcient number of credits available in your account. \n\nWhile using email to fax, you can send email messages with large attachments, up to 20 MB. There is no size limit on receiving fax attachments except the quota limit of your mailbox."
        },
        {

            question: "How does the spam filter work?",
            answer: "MDofficeMail is having an excellent spam filtration system. First it checks multiple RBL databases and the SPF protocol to see if the sender is known as a spammer, or if the email is coming from an email server that is either a known spam server or a machine that is not properly secured to prevent spam. Then it processes incoming messages through the powerful Spam Assassin system. As a third step, suspicious messages can be challenged by the Friends system, giving senders an opportunity to prove their message is not spam. Using a combination of these systems allows a user to actually stop 100% of spam without false positives."
        },
        {

            question: "How does the virus protection work?",
            answer: "MDofficeMail uses a powerful server-based system provided by Avasti. This system is certified by ICSA, the same certification authority that approves systems for MacAfee, Norton and a few other major players. Only trust ICSA-Certified virus scan systems. The system is installed on our email servers, so users don’t have to worry with administration or updates."
        },
        {

            question: "What if I use PGP or S/MIME for email encryption?",
            answer: "MDofficeMail supports and supplements PGP or S/MIME encryption. Even if you encrypt email messages with PGP or S/MIME, the message headers still remain open. 'To:'', 'From:', 'Subject:' etc. header fields may disclose your identity and contain confidential information. Using a MDofficeMail encrypted tunnel to transport these messages creates an even higher level of security. PGP encryption is supported directly within MDofficeMail classic WebMail interface."
        },
        {

            question: "How do I manage my Faxtone account and MDofficeMail secure email account(s)?",
            answer: "An account administration control panel that lets you completely manage all of the user-level functions of your MDofficeMail account. For all plans which include multiple accounts, we provide a master email hosting control panel that lets you create, delete, define and manage user email accounts while allowing each individual user to manage all of their own user-level functions. If domain hosting is included in your plan, a domain admin panel is provided which allows user management, routing & filtering, reports, and other domain-level functions."
        },
        {

            question: "Do you keep usage logs?",
            answer: "Yes, we do record and maintain session logs as required by HIPAA."
        },


    ];


    const Supportfaq = [
        {

            question: "What is the url for Technical Support documentation?",
            answer: `Faxtone Quick Start Guide:<a href="https://mdofficemail.com/pdf/Faxtone-Quick-Start-Guide.pdf" target="_blank" rel="noopener noreferrer">
          https://mdofficemail.com/pdf/Faxtone_Quick_Start_Guide.pdf
            </a> \n\n Support Documentation: Please visit <a href="https://mdofficemail.com/support/index.html" target="_blank" rel="noopener noreferrer">
            http://mdofficemail.com/support/index.html
            </a> `
        },
        {

            question: "Can you set up Faxtone account for me?",
            answer: "We can assist in configuring your web-portal, email program (Outlook, Windows Mail etc), and set up your domain using Remote Desktop. This is free"
        },
        {

            question: "Do you provide Technical Support?",
            answer: `
            <ul>
            <li>We provide unmatched customer support:</li>
            <li>Email support is available on 24/7 basis (response time ranges between 15 minutes to 12 hours) </li>
            <li>Telephone support is available Mon-Fri from 8.00 AM to 5.00 PM CST</li>
            <li>Chat support is also available.</li>
            </ul>`
        },
        {

            question: "Where are the MDofficeMail email servers located?",
            answer: "MDofficeMail email servers are located in a secure data center attached to our office in Illinois, and California."
        },
    ]
    const toggleFaq = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    return (
        <div className="faq-container">
            {/*----------------------------- faq-section1 -------------------------*/}
            <div className="faq-section1">


                <Row className="justify-content-center pt-5">

                    <Col lg={5}>
                        <h1 className="pb-md-5 py-2 ps-3">Set-up & Free Trial</h1>
                        <img src="http://localhost:3000/static/media/gqWzgH_A0dPLCITRlSl7w-transformed.5ceb3be829de1242d6b7.jpeg" width="100%" />
                    </Col>
                    <Col lg={5}>
                        <div >
                            {setupAndTrialFAQs.map((faq, index) => (
                                <Card
                                    key={index}
                                    className={`faq ${activeIndex === index ? "active" : ""} my-4`}
                                    onClick={() => toggleFaq(index)}
                                >
                                    <Card.Body>
                                        <div className="faq-header d-flex justify-content-between">
                                            <Card.Title className="faq-title">{faq.question}</Card.Title>
                                            <Button variant="link" className="faq-toggle">
                                                <Button variant="link" className="faq-toggle">
                                                    <FontAwesomeIcon
                                                        icon={activeIndex === index ? faTimes : faChevronDown}
                                                    />
                                                </Button>
                                            </Button>
                                        </div>
                                        <Collapse in={activeIndex === index}>
                                            <div>
                                                {faq.answer.split('\n\n').map((paragraph, i) => (
                                                    <Card.Text key={i} className="faq-text" dangerouslySetInnerHTML={{ __html: paragraph }} />
                                                ))}
                                            </div>
                                        </Collapse>
                                    </Card.Body>
                                </Card>
                            ))}
                        </div>
                    </Col>
                </Row>

            </div>




            {/*----------------------------- faq-section3 -------------------------*/}
            <div className="faq-section3">


                <Row className="faq-container justify-content-center">
                    <h1 className="text-center py-md-5 py-2">Security & Compliance</h1>
                    {SecurityFaqData.map((faq, index) => (
                        <Col lg={5}>
                            <Card
                                key={index}
                                className={`faq ${activeIndex === index ? "active" : ""} my-4`}
                                onClick={() => toggleFaq(index)}
                            >
                                <Card.Body>
                                    <div className="faq-header d-flex justify-content-between">
                                        <Card.Title className="faq-title">{faq.question}</Card.Title>
                                        <Button variant="link" className="faq-toggle">
                                            <FontAwesomeIcon
                                                icon={activeIndex === index ? faMinus : faPlus} // Use faPlus and faMinus here
                                            />
                                        </Button>
                                    </div>
                                    <Collapse in={activeIndex === index}>
                                        <div>
                                            {faq.answer.split('\n\n').map((paragraph, i) => (
                                                <Card.Text key={i} className="faq-text" dangerouslySetInnerHTML={{ __html: paragraph }} />
                                            ))}
                                        </div>
                                    </Collapse>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}

                </Row>

            </div>








            {/*----------------------------- faq-section2 -------------------------*/}
            <div className="faq-section2 ">

                <Row className="justify-content-center pt-5 align-items-center">
                    <Col lg={5}>
                        <div className="aboutusfaq-content pe-5 me-4">
                            <h1 className="mb-md-5">About us & how we can help</h1>
                            <p>MDofficeMail was started by a group of young entrepreneurs with ample of experience in serving US Healthcare, and certified in HIPAA Privacy and Security Awareness.</p>
                            <p className="py-md-3">MDofficeMail is the sister concern of <Link className="link" target="_blank" to="https://vinfonet.com/">Vision Infonet Inc</Link>, <b>a 12-year-old Illinois corporation</b> serving US Healthcare with Software Development, Medical Billng, Medical Transcription and other back-office services.</p>
                            <p>Vision Infonet also owns a <b>50-year-old California LLC</b>, <Link className="link" target="_blank" to="https://vinfonet.com/">Practice Management System</Link> serving Bay Area physicians for the past 50 years with IT and Billing services.</p>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <div className="featuresfaq ">
                            <p className="mb-md-5 pb-md-3"><b>MDCare</b> EMR/PMS is one of the software product and service from Vision Infonet. MDCare is a certified, high-end, and web-based software with most advanced features.</p>

                            {Supportfaq.map((faq, index) => (
                                <Card
                                    key={index}
                                    className={`faq ${activeIndex === index ? "active" : ""} my-4`}
                                    onClick={() => toggleFaq(index)}
                                >
                                    <Card.Body>
                                        <div className="faq-header d-flex justify-content-between">
                                            <Card.Title className="faq-title">{faq.question}</Card.Title>
                                            <Button variant="link" className="faq-toggle">
                                                <Button variant="link" className="faq-toggle">
                                                    <FontAwesomeIcon
                                                        icon={activeIndex === index ? faTimes : faChevronDown}
                                                    />
                                                </Button>
                                            </Button>
                                        </div>
                                        <Collapse in={activeIndex === index}>
                                            <ul>
                                                {faq.answer.split('\n\n').map((paragraph, i) => (
                                                    <Card.Text key={i} className="faq-text" dangerouslySetInnerHTML={{ __html: paragraph }} />
                                                ))}
                                            </ul>
                                        </Collapse>
                                    </Card.Body>
                                </Card>
                            ))}
                        </div>
                    </Col>
                </Row>


            </div>





        </div>
    )
}

export default Faq
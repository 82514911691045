import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import '././css/contactus.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faFax, faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Contactus = () => {
    return (
        <div className='contactus'>
            {/* <img src="https://elements-resized.envatousercontent.com/envato-shoebox/e07a/7527-07a3-4ccc-8f00-30b9d6eeb19e/61dc16cfdfebe107c36d3f02_withmeta.jpg?w=800&cf_fit=scale-down&mark-alpha=18&mark=https%3A%2F%2Felements-assets.envato.com%2Fstatic%2Fwatermark4.png&q=85&format=auto&s=0edd48e560f580cf6f6b19272ebbf918819bc54cfabe08171655a6fac5199cfc" width="100%"/> */}
       
                <Row className='py-md-5 justify-content-center align-items-center'>
                    <Col>
                        <div className='contact-div'>
                            <Card>
                                <Card.Body>
                                    <Row className='justify-content-center align-items-center px-md-3'>
                                        <Col lg={2}>
                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </Col>
                                        <Col lg={10}>
                                            <Card.Title>
                                                Email
                                            </Card.Title>
                                            <Card.Text>
                                                info@mdofficemail.com
                                            </Card.Text>
                                            <Card.Text>
                                                clientsupport@mdofficemail.com
                                            </Card.Text>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Row className='justify-content-center align-items-center px-md-3'>
                                    <Col lg={2}>
                                        <FontAwesomeIcon icon={faPhone} />
                                    </Col>
                                    <Col lg={10}>
                                        <Card.Body>
                                            <Card.Title>
                                                Phone:
                                            </Card.Title>
                                            <Card.Text>
                                                1-877-477-8999 ext 22
                                            </Card.Text>
                                            <Card.Text>
                                                1-877-477-8999 ext 21 (Mon-Fri, 8-5 Central)
                                            </Card.Text>
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Card>
                            <Card>
                                <Row className='justify-content-center align-items-center px-md-3'>
                                    <Col lg={2}>
                                        <FontAwesomeIcon icon={faFax} />
                                    </Col>

                                    <Col lg={10}>
                                        <Card.Body>

                                            <Card.Title>
                                                Fax:
                                            </Card.Title>
                                            <Card.Text>
                                                1-630-778-0634
                                            </Card.Text>
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Card>
                            <Card>
                                <Row className='justify-content-center align-items-center px-md-3'>
                                    <Col lg={2}>
                                        <FontAwesomeIcon icon={faLocationDot} />
                                    </Col>
                                    <Col lg={10}>
                                        <Card.Body>
                                            <Card.Title>
                                                Corporate & Illinois Office :
                                            </Card.Title>
                                            <Card.Text>
                                                2055 Comprehensive Dr, Aurora, IL 60505
                                            </Card.Text>
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </Col>
                    <Col>
                        <img src="https://www.shutterstock.com/shutterstock/photos/1706068174/display_1500/stock-photo-contact-us-or-customer-support-hotline-people-connect-businessman-using-a-laptop-with-the-email-1706068174.jpg" width="100%" />
                    </Col>
                </Row>
         
        </div>
    )
} 

export default Contactus
import React from 'react'
import './Home.css'
import Banner from './Banner';
import SecureFaxService from './SecureFaxService';

import FaxtoneFeaturesAndAdvantages from './FaxtoneFeaturesAndAdvantages ';

import FaxIntegration from './FaxIntegration';
import Archival from './Archival';
import Contactform from './Contactform';

const Home = () => {


    return (
        <div className='faxtone-section'>
            <div >

                <Banner />
                <SecureFaxService />
               
                {/* <Cardscontainer/> */}
                <FaxIntegration />
                <FaxtoneFeaturesAndAdvantages />
                {/* <HipaaCompliantFaxQuote /> */}
                <Archival/>
                <Contactform/>



            </div>
        </div>
    )
}

export default Home

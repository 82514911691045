import React from 'react'
import '././css/pricingpage.css'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Pricingpage = () => {



    const faxaccdta = [
        "<b>US Local or Toll-free number..</b>",
        "<b>Free MDofficeMail email account</b> for secure sending/receiving of faxes through email.",
        "<b>Facility to send/receive secure faxes from Email.</b>",
        "<b>Facility to access Faxtone Web-portal:</b> You can send and receive faxes from here, customize your settings, add and edit users and much more.",
        "<b>Desktop Application:</b> Send and receive faxes from your desktop.",
        "<b>Smart phone App:</b> You can send and receive faxes from your iPhone/iPad, Android.",
        "<b>Business Associate Agreement</b> We sign BAA with the users as required by HIPAA with regards to PHI that is exchanged using our service.",
        "<b>Telephone, Remote Desktop, Email, and Chat Support</b> at no extra cost to assist clients for smooth takeoff with the account."
    ]

    const mdaccdata = [
        "<b>MDVault:</b> End-to-end encryption for security and compliance at recipient's end",
        "<b>5 GB of storage. Email filtering, Virus protection, and Spam protection.</b>",
        "<b>Legal Archival</b> upto 10 years. Msgs can not be edited or deleted. (Optional)",
        `<b>Intelligent Automation</b> and all other features of MDofficeMail service <a href="https://mdofficemail.com/Intellignet.html" target="_blank" rel="noopener noreferrer">
         (details..)
            </a> `,
        "<b>Secure access</b> from web, POP, IMAP, SMTP, iPhone and other smart phones.",
        "<b>Customization </b> with your own logo and banner. (for accounts with own domain)",
        "<b>Online Calender,</b> File sharing, and many more features",
        "<b>Advanced features</b> like 'Safe recipient', 'Forced expiring'",
        "<b>'Email Your Doc' - </b> Anyone can initiate secure message to MDofficeMail user.",
        `<b>MS Outlook Plug-In -</b> Reminds  the user whether message is being sent plain/encrypted.`,
        "<b>Personalized spam settings,</b> personalized 'Family & Friends' settings etc"
    ]


    const standardplandata = [
        {
            heading: "Small Practice / Personal Plan",
            text1: "(1-4 mail accounts)",
            text2: `<b>	
$ 1.89 / mail box / mo</b> <br/> (annual subscription)
(monthly/quarterly subscription - $ 2.10/mo)`
        },
        {
            heading: "Medium Practice Plan",
            text1: "Medium Practice Plan",
            text2: `<b>	$ 1.44 / mail box / mo</b>  <br/> ( annual subscription)<br/>
( monthly subscription - $1.60/mo)`
        },
        {
            heading: "Large Practice Plan",
            text1: "Large Practice Plan",
            text2: `<b>$ 1.12 / mail box / mo </b>  <br/> (annual subscription)<br/> (monthly subscription - $1.25/mo)`
        },
        {
            heading: "Hospital Plan",
            text1: "	(>100 mail accounts)",
            text2: `<b>	Please contact us.</b>`
        },
        {
            heading: "Legal Archival (optional)",
            text1: "	Legal Archival is very important to meet the Seven-Year Retention Rule in HIPAA. Legal Archival is also a' Proof of Email Delivery' to protect against possible litigations.",
            text2: `<b>$ 0.81 / mail box / mo</b>  <br/> (annual subscription)<br/>
(monthly/quarterly subscription - $0.90/mo)`
        },
        {
            heading: "HIPAA Business Associate Agreement preparation and processing",
            text1: "	(one time charge)",
            text2: `Waived`
        }

    ]

    const standardpricingData = [
        {
            plan: '<b>Faxtone service plan with one US local or toll-free fax number</b>',
            accounts: '1 fax line',
            price:
                '<b>$ 3.15 / line / mo (annual subscription)</b><br/>(Monthly subscription - $3.50/mo)',
        },
        {
            plan: '<b>Fax transmission credits (pre-paid)</b>',
            accounts: '	1 credit = sending or receiving 1 page',

            credits: [
                { quantity: '200 credits', price: '	$10.00', pricePerPage: '(5¢/page)' },
                { quantity: '500 credits', price: '	$25.00', pricePerPage: '(5¢/page)' },
                { quantity: '1111 credits', price: '$50.00', pricePerPage: '(4.5¢/page)' },
                { quantity: '2500 credits', price: '$100.00', pricePerPage: '(4¢/page)' },
            ]
        },
        {
            plan: '<b>Porting of an existing fax number to Faxtone service</b>',
            accounts: 'depending on feasibility',
            price: '$ 20.00 / one time',
        },
        {
            plan: 'HIPAA Business Associate Agreement preparation and processing',
            accounts: '(one time charge)',
            price: '<s>$ 19.99</s> Waived',
        }
    ];



    // const standardpricingData = [
    //     {
    //         plan: 'Small Practice / Personal Plan',
    //         accounts: '(1-4 mail accounts)',
    //         price:
    //             '<b>$ 1.89 / mail box / mo </b>(annual subscription)(monthly/quarterly subscription - $ 2.10/mo)',
    //     },
    //     {
    //         plan: 'Medium Practice Plan',
    //         accounts: '(5-25 mail accounts)',
    //         price:
    //             '<b>$ 1.44 / mail box / mo</b> (annual subscription) (monthly subscription - $1.60/mo)',
    //     },
    //     {
    //         plan: 'Large Practice Plan',
    //         accounts: '(26-100 mail accounts)',
    //         price:
    //             '<b>$ 1.12 / mail box / mo </b>(annual subscription) (monthly subscription - $1.25/mo)',
    //     },
    //     {
    //         plan: 'Hospital Plan',
    //         accounts: '(>100 mail accounts)',
    //         price: '<b>Please contact us.</b>',
    //     },
    //     {
    //         plan: 'Legal Archival (optional)',
    //         accounts:
    //             'Legal Archival is very important to meet the Seven-Year Retention Rule in HIPAA. Legal Archival is also a “Proof of Email Delivery” to protect against possible litigations.',
    //         price: '<b>$ 0.81 / mail box / mo </b>(annual subscription) (monthly/quarterly subscription - $0.90/mo)'
    //     },
    //     {
    //         plan: 'HIPAA Business Associate Agreement preparation and processing',
    //         accounts: '(one time charge)',
    //         price: 'Waived.',
    //     },
    // ];

    const optionaladdons = [
        {
            heading: "Additional disc space for each mail box",
            plan: "<sup>$</sup> <span>1.00 </span> <sub>/GB / mo</sub>"
        },
        {
            heading: "New domain registration charges",
            text: "Only with domain plans",
            plan: "<sup>$</sup> <span>20.00</span>  <sub>year</sub>"
        },
        {
            heading: "Customization charges",
            text: "for domain plans",
            plan: "<sup>$</sup> <span> 5.00 </span> <sub>mo</sub>"
        },
        {
            heading: "Domain setup fee (for Domain plans)",
            text: "One time charge",
            plan: "<sup>$</sup> <span> 29.90 </span> <sub>waived</sub>"
        },
        {
            heading: "Continuation of Legal Archival of discontinued accounts",
            text: "Unlimited data",
            plan: "<sup>$</sup> <span>1.00</span>  <sub>/mail box / month</sub>"
        }
    ];



    const notedata = [
        "Annual subscription option will be available in the 2nd page of the order form.",
        "You can upgrade / downgrade / cancel your account anytime without any obligation.",
        "If you are subscribing for only one email account, you will be charged on quarterly / annual basis. There is no montly payment option.",
        "Refund for remaining period will be made upon cancellation of service for annual subscription only.",
        "30-day refund is not applicable for domain customization charges, secure web-form, and consumed fax transmission credits."
    ]





    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2, // Show 2 cards per slide
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024, // For medium and small screens
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };






    // -----naviagte to signup page
    const handleclick = () => {
        window.open("https://mdofficemail.com/subscriptions/orderservices.aspx", "_blank");
    }
    return (
        <div className='pricingpage'>

            {/*---------------------------------row-1-------------------------------*/}
            <Row className='row-1 justify-content-center align-items-center'>
                {/* <Col xl={6}>
                    <img src="http://localhost:3000/static/media/gqWzgH_A0dPLCITRlSl7w-transformed.5ceb3be829de1242d6b7.jpeg" width="100%" />
                </Col> */}
                <Col xl={6} className='text-center'>
                    <h1 className='pb-4'>Plans & Price</h1>

                    <p > We offer customized plans to suit the requirement of each practice and hospital,
                        Each fax line costs just $3.15/mo and $0.04/page of transmission,
                        Each fax line includes a free secure email account. Select a US local or toll-free.</p>

                    <Button className='btn mt-4'>Read More</Button>
                </Col>
            </Row>





            {/*---------------------------------row-5-------------------------------*/}

            <div className='StandardPlanstabledata'>

                <Row className='row-4  px-5 mx-5 justify-content-center align-items-center '>

                    <h1 className='text-center  py-5 mb-5 lh-base px-5'>Price: Faxtone, the HIPAA compliant Fax Service</h1>
                    <table className='mb-md-5 '>

                        <tbody>
                            {standardpricingData.map((item, index) => (
                                <tr key={index}>
                                    <td dangerouslySetInnerHTML={{ __html: item.plan }} />
                                    <td >{item.accounts}</td>
                                    <td style={{ padding: '10px' }}>
                                        {item.credits ? (
                                            <div>
                                                {item.credits.map((credit, i) => (
                                                    <div key={i} style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0' }}>
                                                        <span style={{ width: '40%' }}>{credit.quantity}</span>
                                                        <span style={{ width: '30%' }}>{credit.price}</span>
                                                        <span>{credit.pricePerPage}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <span dangerouslySetInnerHTML={{ __html: item.price }} />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>


                </Row>
                <div className='signup-div me-md-5 pe-md-4 d-flex pt-md-5'>
                    <h4>Faxtone - HIPAA Fax</h4>
                    <Button onClick={handleclick} className='mx-3 btn'>SignUp</Button>
                </div>


            </div>

            {/*---------------------------------row-4-------------------------------*/}
            <div className='pricecards-section '>

                <Row className='row-4 px-5 mx-5 justify-content-center align-items-center'>

                    <h1 className='text-center pt-4 pb-5 lh-base mb-5'>Price: MDofficeMail(Email Hosting) Standard Plans</h1>
                    {/* {
                        standardplandata.map((resp, id) => {
                            return (
                                <Col lg={4} className='pb-5 mb-5'>
                                    <Card key={id} className='p-md-4 mx-2'>
                                        
                                        <div className='icon'>{id + 1}</div>
                                        <Card.Body className='text-center'>
                                            <Card.Title className='fw-bold lh-base'>
                                                {resp.heading}
                                            </Card.Title>
                                            <Card.Text  >
                                                {resp.text1}
                                            </Card.Text>
                                            <Card.Text dangerouslySetInnerHTML={{ __html: resp.text2 }} />


                                        </Card.Body>
                                      
                                    </Card>
                                </Col>
                            )
                        })
                    } */}


                    <table>
                        {
                            standardplandata.map((resp, ind) => {
                                return (
                                    <tr id={ind}>
                                        <td>{resp.heading}</td>
                                        <td>{resp.text1}</td>
                                        <td dangerouslySetInnerHTML={{ __html: resp.text2 }} />
                                    </tr>

                                )
                            })
                        }
                    </table>


                </Row>

                <div className='signup-div me-md-5 pe-md-4  d-flex pt-md-5'>
                    <h4>Standard Plans</h4>
                    <Button onClick={handleclick} className='mx-3 btn'>SignUp</Button>
                </div>

            </div>




            {/*---------------------------------row-6-------------------------------*/}

            <div className="securecontact ">
                <Row className='justify-content-center mx-5 row-6 px-5  align-items-center'>
                    <Col md={12} className='col1'>
                        <h1 className='text-center py-5 lh-base mb-5'>Price: Secure Contact Form for your website with Customization</h1>
                        <table className='mb-md-5'>

                            <tbody>

                                <tr >
                                    <td data-title="One" className='fw-bold'>
                                        Secure Contact Form for your website. Dynamic and Responsive. Cutomized fields, design, your own banner, and logo.
                                        <br />
                                        <span style={{ fontWeight: 'normal' }}></span>
                                    </td>
                                    <td data-title="Two">requires MDofficeMail email account to deliver submitted form data.</td>
                                    <td data-title="Three"><span className='fw-bold'>$ 6.30 / form / mo</span> (annual subscription)
                                        (monthly/quarterly subscription - $7.00/mo)
                                    </td>
                                </tr>

                            </tbody>
                        </table>


                    </Col>
                </Row>
                <div className='signup-div me-md-5 pe-md-4 d-flex pt-md-5'>
                    <h4>Secure Contact Form	</h4>
                    <Button onClick={handleclick} className='mx-3 btn'>SignUp</Button>
                </div>

            </div>

            {/*---------------------------------row-7-------------------------------*/}
            <div className='row-7 py-md-5 px-md-5'>
                <Row className='justify-content-center align-items-center px-5 mx-5'>
                    <h1 className='px-md-5 mx-lg-5 text-center lh-base py-md-5'>Optional Add-ons: Extra Storage, Legal Archival, and Domains</h1>
                    {/* {
                        optionaladdons.map((resp, ind) => {
                            return (
                                <Col xl={2} md={5} lg={5} className='py-md-5'>
                                    <Card key={ind} className='my-3'>
                                        <Card.Body>
                                            <Card.Title dangerouslySetInnerHTML={{ __html: resp.plan }}></Card.Title>
                                            <Card.Text>{resp.heading}</Card.Text>
                                            <Card.Text>{resp.text}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    } */}
                    <Col lg={8}>
                        <table>
                            {
                                optionaladdons.map((resp, ind) => {
                                    return (
                                        <tr>

                                            <td>{resp.heading}</td>
                                            <td>{resp.text}</td>
                                            <td dangerouslySetInnerHTML={{ __html: resp.plan }} />
                                        </tr>

                                    )
                                })
                            }
                        </table>
                    </Col>
                </Row>
            </div>






            {/*---------------------------------row-2-------------------------------*/}
            <div className='row-2 px-lg-5 py-lg-5'>

                <Row className='py-md-5 px-5'>
                    <h1 className='py-4'>Each Faxtone Account Includes:</h1>
                    <ul className='ms-md-4'>
                        {
                            faxaccdta.map((res, id) => {
                                return (
                                    <li key={id} dangerouslySetInnerHTML={{ __html: res }} />
                                )
                            })
                        }
                    </ul>
                </Row>

            </div>

            {/*---------------------------------row-3-------------------------------*/}
            <div className="row-3 px-lg-5 py-lg-5 ">

                <Row className='px-5' >
                    <h1 className='py-4 '>Each MDofficeMail email account includes:</h1>
                    <ul className='ms-md-4'>
                        {
                            mdaccdata.map((res, id) => {
                                return (
                                    <li key={id} dangerouslySetInnerHTML={{ __html: res }} />
                                )
                            })
                        }
                    </ul>
                </Row>

            </div>

            {/*---------------------------------row-8-------------------------------*/}
            <div className='note-section row-8 py-md-5 px-5'>

                <Row className='px-lg-5 py-md-5 py-3 px-5'>
                    <h1>Note:</h1>
                    <ul className='ms-md-4 pt-md-5'>
                        {
                            notedata.map((resp, id) => {
                                return (
                                    <li key={id}>{resp}</li>
                                )
                            })
                        }
                    </ul>
                </Row>

            </div>

        </div>
    )
}

export default Pricingpage

import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import './layout.css'
import imglogo from '../Images/tonelogo2.png'
import { Link } from 'react-router-dom'

const Header = () => {

    return (
        <div className='header'>
            <Navbar expand="lg" >
                <Navbar.Brand href="#home" className='ms-lg-5'>
                    <img src={imglogo} alt='logoimg' width="100%" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="justify-content-center flex-grow-1 navbar-links" >
                        <Nav.Link as={Link} to="/" >Home</Nav.Link>
                        <Nav.Link as={Link} to="/how_hipaa_fax_works" >How It Works</Nav.Link>
                        <Nav.Link as={Link} to="/hipaa_fax_features" >Features</Nav.Link>
                        <Nav.Link as={Link} to="/hipaa_fax_security" >Security</Nav.Link>
                        <Nav.Link as={Link} to="/hipaa_fax_faq" >FAQ</Nav.Link>
                        <Nav.Link as={Link} to="/hipaa_fax_plans" >Pricing</Nav.Link>
                        <Nav.Link as={Link} to="/hipaa_fax_contact" >Contact Us</Nav.Link>
                        <Nav.Link as={Link} to="/login" >Login</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default Header

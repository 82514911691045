import React, { useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import '././css/features.css'
import Cardscontainer from './MainHome/Cardscontainer'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import imga from '../Images/freemaila.png'
// import imgb from '../Images/portnumb.png'


import imga from '../Images/freemailb.png'
import imgb from '../Images/portnuma.png'


const Features = () => {

    const [showAll, setShowAll] = useState(false);
    const [showAll2, setShowAll2] = useState(false)

    const data = [
        "Fax line is never busy!. Number of fax messages from different senders can be received simultaneously.",
        "Send and receive faxes via email, website, API Fax from your desktop and smart phone.",
        "Secure and compliant: SSL encryption for the online fax and API fax interfaces",
        "Email fax server-to-server TLS/SSL encryption support, Email fax PGP support",
        "Incoming email fax is password protected PDF. (in case that email account is not HIPAA compliant)",
        "Unlimited users/emails that you create at no additional per-user fee.",
        "Unlimited folders that you create and manage for storing all of your faxes at no additional fee.",
        "Capability to create fax lists for automated faxing and to find a contact quickly. including CSV file upload list creation.",
        "Upload CSV file for contact list creation.",
        "Outbound fax status notification via web site and/or email on a per-job basis. Download status to CSV from our web site for offline analysis.",
        "A custom cover sheet logo can be added to personalize your faxes.",
        "Ability to forward received faxes automatically to multiple users' email addresses on a per-fax-number basis.",

    ]

    const features = [
        "Clean, easy to use Faxtone Web portal.",
        "Multiple documents can be attached to each fax, combination of all our supported document types.",
        "No telephone or fax machine or fax modem required.",
        "Enhanced productivity. No need to print documents to fax them. Send them directly from computer.",
        "User-friendly service.",
        "Faxes can be received either in .pdf or .tiff format.",
        "Cost-effective operations – Save time, labor and money.",
        "Close to100% reliability, High scalability.",
        "Unlimited users can be created in each account. Each user can send and receive faxes from their own email account.",
        "Never busy!. Number of fax messages from different senders can be received simultaneously.",
        "Send and receive faxes via email, website, API Fax from your desktop and smart phone.",
        "Secure and compliant: SSL encryption for the online fax and API fax interfaces",
        "Email fax server-to-server TLS/SSL encryption support, Email fax PGP support",
        "Incoming faxes to non-MDofficeMail email accounts are encrypted automatically, which can be viewed and downloaded securely.",
        "Unlimited folders to sort and store your faxes on the web-portal.",
        "Fax status notification of all sent faxes via web site and/or email..",
        "Different cover sheet designs to choose from. Your logo can be added to cover sheet to customize your faxes.",
        "Forward received faxes to multiple email ids on a per-fax-number basis.",
        "Ability to automatically forward received faxes to multiple users' email ids on a per-fax-number basis.",
        "Access control on per-user and per-line basis for enhanced security.",
        "Incoming faxes are automatically stored in the Faxtone web portal as well as emailed to you using our secure 'Fax to Email' system.",
        "Each Faxtone accounts can support multiple fax numbers. Each fax number can be accessed securely with it's own user name and password.",
        "Customized faxes can be composed from Faxtone Web-portal with/without cover sheet, with/without disclaimer, from desired fax number ine the account.",
        "User-specific Contacts list can be maintained in the Faxtone Web-portal. Just select the recipient/s, attach documents and send fax!",
        "Add digital signature to your fax message!!. Copy the saved image of your signature, copy and paste it on to the cover-sheet. It is that easy!!"
    ]



    const row2data = [
        {
            heading: "MDofficeMail Secure Email Account",
            para: "for convenient, secure, and HIPAA compliant faxing through email, Email-to-fax and Fax-to-email",
            fax: "Free email",
            img1: imga
        },
        {
            heading: "Port your existing fax number for FREE",
            para: "Move your fax service to Faxtone for FREE!! There will be NO interruption in service.",
            fax: "Free Porting",
            img1: imgb
        }
    ];



    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const featuresbutton = () => {
        setShowAll2(!showAll2);
    };
    return (
        <div className='features-cls'>
            <Row className='px-md-5  py-2 row-1'>
                <Col lg={12} className='py-md-5'>
                    <h1 className='py-2 py-md-4  lh-base'>Features and Advantages of Faxtone</h1>
                    <Row className='data-row'>

                        {data.slice(0, showAll2 ? data.length : 6).map((res, id) => {
                            return (
                                // <h2 key={id} className={`ribbon blue ${id % 2 === 0 ? 'bottom' : 'top'}`}>
                                <Col lg={6} className='px-5'>
                                    <h2 key={id} className="ribbon blue bottom lh-lg p-5">
                                        {res}
                                    </h2>
                                </Col>
                            )
                        })}
                        <button onClick={featuresbutton} className='btn btn-primary my-5 ms-5'>
                            {showAll2 ? "Read Less" : "Read More"}
                        </button>

                    </Row>
                </Col>

            </Row>

            {/* <Col lg={6}>
                    <div>
                        <h1>Free email</h1>
                        <h2>MDofficeMail Secure Email Account</h2>
                        <p>for convenient, secure, and HIPAA compliant faxing through email, Email-to-fax and Fax-to-email</p>
                        <img src="https://elements-resized.envatousercontent.com/envato-shoebox/twenty20/production/uploads/items/72d7a915-8a78-49fd-a06e-cdface8c5f7b/source?w=1600&cf_fit=scale-down&mark-alpha=18&mark=https%3A%2F%2Felements-assets.envato.com%2Fstatic%2Fwatermark4.png&q=85&format=auto&s=645474ef5227596f2ee01e65832bbb0626a3a3a12a91b8532fc6cb73e3a790b6" alt='freeemail' width="100%" />
                    </div>
                    <div>
                        <h1>Free Porting</h1>
                        <h2>Port your existing fax number for FREE</h2>
                        <p>Move your fax service to Faxtone for FREE!! There will be NO interruption in service.</p>
                        <img src="https://elements-resized.envatousercontent.com/envato-shoebox/e914/c13c-c6aa-4aa1-b950-ece4734a2493/DSC_9444.jpg?w=1600&cf_fit=scale-down&mark-alpha=18&mark=https%3A%2F%2Felements-assets.envato.com%2Fstatic%2Fwatermark4.png&q=85&format=auto&s=a9392bd118fbeb5d0cb795fc87003f3c4e98d2609eb73d11312269ffd1a28e07" alt='img' width="100%" />

                    </div>
                </Col> */}



            {/* <Cardscontainer /> */}
            <div className='row-2 justify-content-center px-5 '>

                {/* {
                    row2data.map((resp, id) => {
                        return (
                            <Col lg={5} className='border-right mx-5 px-5'>
                                <Card.Title className='mb-5 ms-2'>{resp.fax}</Card.Title>
                                <Card key={id} className={`card-list__item py-3 px-2 ${id % 2 !== 0 ? 'card-list__item--alt' : ''}`} >
                                    <Card.Body>
                                        <Row>
                                            <Col lg={6}>

                                                <Card.Text>{resp.heading}</Card.Text>
                                                <Card.Text>{resp.para}</Card.Text>
                                            </Col>
                                            <Col lg={6}>
                                                <Card.Img src={resp.img1} width="100%" />
                                            </Col>
                                        </Row>


                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })
                } */}
                <Row className='justify-content-center align-items-center'>
                    <Col lg={6}>
                        <h1>Free email</h1>
                        <p>MDofficeMail Secure Email Account</p>
                        <p>for convenient, secure, and HIPAA compliant faxing through email, Email-to-fax and Fax-to-email</p>
                    </Col>
                    <Col lg={6}>
                        <img src={imga} width="100%" />
                    </Col>
                </Row>
                <Row className='justify-content-center align-items-center'>
                    <Col lg={6} className='img2'>
                        <img src={imgb} width="100%" className='me-5' />
                    </Col>
                    <Col lg={6} className='colb'>
                        <h1>Free Porting</h1>
                        <p>Port your existing fax number for FREE</p>
                        <p>Move your fax service to Faxtone for FREE!! There will be NO interruption in service.</p>
                    </Col>
                </Row>



            </div>
            <Row className='row-3 py-md-5 py-2 '>
                <h1 className='my-2 mt-md-5'>Faxtone Features</h1>

                <Col lg={12} xl={9} sm={12} md={12} className='my-md-5 '>

                    <h2 className='lh-lg mb-5 '>User-friendly and secure features that make Faxtone a pleasure-to-use HIPAA Fax service.</h2>
                    {
                        features.slice(0, showAll ? features.length : 6).map((dta, id) => {
                            return (
                                <p key={id} className='d-flex'>
                                    <span><FontAwesomeIcon className='faicon mt-2' icon={faCheck} /></span>
                                    <span className='para ms-3'>{dta}</span>
                                </p>
                            );
                        })
                    }

                    <button onClick={toggleShowAll} className='btn btn-primary mt-4'>
                        {showAll ? "Read Less" : "Read More"}
                    </button>
                </Col>
            </Row>

        </div>
    )
}

export default Features
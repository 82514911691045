import React from 'react'
import imagea from '../../Images/gqWzgH_A0dPLCITRlSl7w-transformed.jpeg'
import './Home.css'
import { Col, Row } from 'react-bootstrap'

const SecureFaxService = () => {
    return (

        <div className="securefaxservice py-5">
            {/* <Row>
                <Col lg={3}>
                    <div className='section__bubble bubble bubble--1  mt-5 pt-5'></div></Col>
                <Col lg={6} className='mt-5'>
                    <div className='head-para pt-5 text-start'>
                        <h1> Secure Fax Service</h1>
                        <p className='mt-5'>Send faxes from any smart phone using Faxtone Secure Fax Service.</p>
                        <ul>
                        <li>Highly secure, HIPAA compliant digital fax service.</li>
                        <li>Say goodbye to fax machines, telephone lines, and paper - reduced carbon footprint.</li>
                        <li>Make your faxes available all the time, from anywhere - your office, hospital, or home.</li>
                        </ul>
                    </div>
                </Col>
            </Row>
           
            <Row className='pb-5 mb-5'>
                <Col lg={6} className='ms-lg-5 ps-xl-5 mt-lg-5'>
                    <img className='ms-lg-5 ps-lg-5 pt-lg-5 mt-xl-5' src={imagea} alt='img' width="100%" />
                </Col>
                <Col lg={4} className='ms-lg-5 ps-xl-5 mt-lg-5'>
                    <div className='ms-lg-5 ps-lg-5 section__bubble bubble  bubble--2 '></div>
                </Col>

            </Row> */}
            <Row className='py-3'>
                <Col lg={6} className=' mt-lg-5 pt-4'>
                    <div className='section__bubble bubble bubble--1  mt-5 pt-5'></div>
                    {/* <img className=' pt-lg-5 mt-xl-5 ps-lg-5 ms-lg-5' src={imagea} alt='img' width="100%" /> */}
                    <img className=' pt-lg-5 mt-xl-5 ps-lg-5 ms-lg-5' src="https://elements-resized.envatousercontent.com/envato-shoebox/df00/00cd-8d56-4aa2-a9be-d77eb498462b/231857968.jpg?w=1600&cf_fit=scale-down&mark-alpha=18&mark=https%3A%2F%2Felements-assets.envato.com%2Fstatic%2Fwatermark4.png&q=85&format=auto&s=86a84869c4edda8bcb3800e1a261a2234ce2a89a6e0b34c2ba87da0817be4b68" alt='img' width="100%" />
                </Col>
                <Col lg={6} className='ps-5'>
                    <div className='head-para pt- text-start ms-lg-5 '>
                        <h1> Secure Fax Service</h1>
                        <p className='mt-5'>Send faxes from any smart phone using Faxtone Secure Fax Service.</p>
                        <ul>
                            <li>Highly secure, HIPAA compliant digital fax service.</li>
                            <li>Say goodbye to fax machines, telephone lines, and paper - reduced carbon footprint.</li>
                            <li>Make your faxes available all the time, from anywhere - your office, hospital, or home.</li>
                        </ul>
                    </div>
                    <div className='ms-lg-5 ps-lg-5 section__bubble bubble  bubble--2 '></div>
                </Col>
            </Row>
        </div>

    )
}

export default SecureFaxService
import React from 'react'
import { useState } from 'react';
import { Container, Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '././css/login.css'

const Login = () => {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };
    return (
        <div className='login-form'>
            <Container>

                <Row className='justify-content-center  '>
                    {/* <h1 className='text-center py-md-5 my-md-5'>Secure Login</h1> */}
                 
                    <Col lg={5} >

                        <Form noValidate validated={validated} onSubmit={handleSubmit} className=''>

                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Form.Label className='mb-3'>Email</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="EMail"

                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group><br />
                            <Form.Group className='mt-4' as={Col} md="12" controlId="validationCustom02">
                                <Form.Label className='mb-3'>Password</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    placeholder="Password"

                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>


                            <Button type="submit" className='my-5 btn'>Login</Button><br />
                            <Link to="/">Forget Password?</Link>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Login
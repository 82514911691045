import React from 'react'
import { Col, Row } from 'react-bootstrap'
import image2 from '../..//Images/ij6gGPf3DB9kIHOCOZvM5-transformed.jpeg'
import imagea from '../../Images/gqWzgH_A0dPLCITRlSl7w-transformed.jpeg'
const FaxIntegration = () => {
    const data = [
        "Send HIPAA compliant, secure faxes from your email account and web portal.",
        "Receive faxes to your email account and web portal.",
        "Smart phone app to send and receive faxes from your iPhone/iPad and Android.",
        "Your fax number will never be busy. Multiple faxes can be received simultaneously.",
        "No Downloads, No installations, No hassle!!"
    ]
    return (
        <div className='faxintegratecls py-5 '>
            <Row className='align-items-center py-lg-5 ps-2'>
                <Col lg={6} className='col2'>

                    <h2 className='mb-5 lh-base'>Integrate your Faxtone account with any EHR</h2>
                    <ul >
                        {
                            data.map((dta, id) => {
                                return (
                                    <>

                                        <li className='py-2' key={id}>{dta}</li>

                                    </>
                                )
                            })
                        }
                    </ul>
                    <div >
                        <p className='lh-lg my-4'>By simply accessing your secure email you can view office faxes anytime, anywhere. In addition, you can send faxes from your email or web-portal. Faxtone secure fax service enables you to easily send faxes to multiple recipients, receive faxes from multiple fax numbers and customize all your faxes. Best of all, Faxtone secure fax service saves you time and money while enhancing your faxing experience.</p>
                        <p className='lh-lg '>Easy-to-use Faxtone enables your business to conveniently send and receive faxes practically from anywhere. Subscribe, set it up and start sending and receiving secure faxes within minutes!</p>

                    </div>
                </Col>
                <Col lg={6} className='col1 '>
                    {/* <img className='ms-5 img1' src={imagea} alt='img1' width="100%" />
                    <img className='ms-5 img2' src={image2} alt='img2' width="100%" /> */}
                    <img className='img1' src="https://www.shutterstock.com/shutterstock/photos/2311397605/display_1500/stock-photo-e-document-management-paperless-workplace-e-signing-business-people-sign-electronic-documents-on-2311397605.jpg" alt='img1' width="100%" />
                    {/* <img className='img2' src="https://www.shutterstock.com/shutterstock/photos/1402823630/display_1500/stock-photo-ehr-electronic-health-record-emr-medical-automation-system-medicine-internet-concept-doctor-with-1402823630.jpg" alt='img2' width="100%" /> */}
                    <img className='img2' src="https://www.shutterstock.com/shutterstock/photos/2376268015/display_1500/stock-photo-ehr-acronym-electronic-health-record-concept-refers-to-a-digital-version-of-a-patient-s-2376268015.jpg" alt='img2' width="100%" />
   
                </Col>

            </Row>
        </div>

    )
}

export default FaxIntegration
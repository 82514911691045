import React from 'react'
import { useState } from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import './Home.css'

const Contactform = () => {
    return (
        <div className='contactform py-5'>
            <h1 className='text-center'>Let's get in touch.</h1>
            <p className='text-center my-5'>Feel free to reach out to us using the options below, and our dedicated team will respond to your inquiries promptly.</p>
            <Container>
                <Row className='justify-content-center align-items-center g-5 my-5'>
                    <Col lg={6} className='pe-5'>
                        <img width="100%" src="https://elements-resized.envatousercontent.com/envato-shoebox/8e53/5ede-6fdc-498f-8f8b-4b5f029002a3/4X6A5792.jpg?w=800&cf_fit=scale-down&mark-alpha=18&mark=https%3A%2F%2Felements-assets.envato.com%2Fstatic%2Fwatermark4.png&q=85&format=auto&s=336050f6e8a71ca0311bf2bc94c1e6f438c85f9a4586687e03b49f1944576987" />

                    </Col>
                    <Col lg={6} className='ps-5'>
                        {/* <h1>Contact us</h1> */}
                        <p className='mb-3 fw-bold'>Have a question or feedback? Fill out the form below, and we'll get back to you as soon as possible.</p>
                        <Form className='mt-4' >


                            <Form.Group controlId="validationCustom01">
                                <Form.Label>Full  Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Full Name"

                                />

                            </Form.Group><br />
                            <Form.Group controlId="validationCustom02">
                                <Form.Label>Gmail</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="EMail"

                                />

                            </Form.Group><br />
                            <Form.Group controlId="validationCustom02">
                                <Form.Label>Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Message"
                                    style={{ height: '100px' }}
                                />

                            </Form.Group>



                            <Button className='mt-4 btn' type="submit">Submit form</Button>
                        </Form></Col>
                </Row>
            </Container>
        </div>
    )
}

export default Contactform
import React, { useRef } from 'react'
import { Button, Col, Row } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import image1 from '../../Images/C9VV1m4rQrTXrLxL2y8iN-transformed.jpeg'
import image2 from '../../Images/ij6gGPf3DB9kIHOCOZvM5-transformed.jpeg'


import './Home.css'
const Banner = () => {



    // const data = [
    //     image1,
    //     image2
    // ];

    const data = [
        {
            home3heading: "HIPAA compliant digital fax.",
            home3para: "Faxtone is a secure, HIPPA compliant digital fax service",
            imgs: "https://www.shutterstock.com/shutterstock/photos/2468038535/display_1500/stock-photo-cyber-security-and-data-protection-on-internet-person-touching-virtual-shield-secure-access-2468038535.jpg"
            // imgs:"https://elements-resized.envatousercontent.com/envato-shoebox/c101/c5ff-b11d-41aa-b7fa-fc4329936781/308527888.jpg?w=1600&cf_fit=scale-down&mark-alpha=18&mark=https%3A%2F%2Felements-assets.envato.com%2Fstatic%2Fwatermark4.png&q=85&format=auto&s=47f3a521c4be3c5b962a8dac5dd1642268328058f3145bc754b6de20d8207367"
        },
        {
            home3heading: "HIPAA compliant digital fax.",
            home3para: "Send & receive, HIPPA compliant faxes from email, web portal, desktop app, & smartphone",
            imgs: image2,
            // imgs:"https://elements-resized.envatousercontent.com/envato-shoebox/0790/9d7b-40f8-41d1-bfd0-12fa52944ce9/IMG_0799.jpg?w=1600&cf_fit=scale-down&mark-alpha=18&mark=https%3A%2F%2Felements-assets.envato.com%2Fstatic%2Fwatermark4.png&q=85&format=auto&s=f29a467efb8b05380636b616a5484f92b6158dfb4413ce8f3f008c31486ef339"
        },
        {
            home3heading: "HIPAA compliant digital fax.",
            home3para: "No more fax machines, telephone lines, paper, software or carbon footprint",
            imgs: image1
                //  imgs:"https://elements-resized.envatousercontent.com/envato-shoebox/f6e5/01b8-2b08-402e-b1cc-97a4dd63f7a7/DSC_5112.jpg?w=1600&cf_fit=scale-down&mark-alpha=18&mark=https%3A%2F%2Felements-assets.envato.com%2Fstatic%2Fwatermark4.png&q=85&format=auto&s=3721a0d7d63ff456137590d3c907c5b6b05e636ed988d003b1c3e351dcd06da2"
        }
    ];


    const sliderRef = useRef(null);

    const CustomPrevArrow = ({ onClick }) => (
        <button className="slick-arrow slick-prev" onClick={onClick}>
            <FontAwesomeIcon icon={faArrowLeft} />
        </button>
    );

    const CustomNextArrow = ({ onClick }) => (
        <button className="slick-arrow slick-next" onClick={onClick}>
            <FontAwesomeIcon icon={faArrowRight} />
        </button>
    );

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />
    };

    const slideNext = () => {
        sliderRef.current.slickNext();
    };

    const slidePrev = () => {
        sliderRef.current.slickPrev();
    };


    return (
        <div className='banner-section'>
            <Row className='px-5 align-items-center py-5'>

                <div className="carousel-container mx-5">
                    <Slider {...settings} ref={sliderRef}>
                        {data.map((dta, index) => (
                            <div key={index}>
                                <Row className='align-items-center'>
                                    <Col lg={6} >
                                        <div className='divcls'><h1 className='lh-base'>{dta.home3heading}</h1>
                                            <p className='py-4 lh-lg'>{dta.home3para}</p>
                                            <Button className='btn'>Read More</Button></div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className='imgcls p-5'>
                                            <img src={dta.imgs} alt={`slide ${index + 1}`} width="750px" height="700px" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </Slider>
                    <div className="img-grid-buttons">
                        <button className="img-grid-button" onClick={slidePrev}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                        <button className="img-grid-button" onClick={slideNext}>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                    </div>
                </div>

            </Row>
        </div>
    )
}

export default Banner